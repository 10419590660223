<template>
  <MainMenu :items=this.menuItems />
  <SliderView :items=this.sliderItems />

  <SectionView :header-image="require('../src/assets/section-headers/1-home.jpeg')" id="home" >
    <div class="row">
      <div class="col-md-4">
        <img class="thumbnail mb-4" src="./assets/home-photo.png">
      </div>
      <div class="col section-content mt-3">
        <span class="pl-text">
          <p>
            Firma to ponad 35 lat tradycji naszego zespołu. <br/>
            Zatrudniamy pracowników, którzy są z nami od początku prowadzonej działalności I w tym mamy największy sukces.<br/>
            Mamy duże doświadczenie w relacjach z klientami oraz w przygotowaniu odpowiedniej jakości towaru.<br/>
            Dziś główna produkcja odbywa się w zakresie przygotowania pierza oraz puchu do realizacji wypełnień w meblach tapicerowanych. <br/>
            Pierze posiada certyfikaty FIRA, IDFL, DOWNPASS.<br/>
            Realizujemy zamówienia nie tylko na rynek krajowy, ale również europejski oraz export poza Europę.<br/>
            Naszymi klientami są producenci mebli i producenci poduszek dekoracyjnych oraz typowych wyrobów pościelowych takich jak kołdry, poduszki czy odzieży sportowej.
          </p>
        </span>

        <span class="en-text">
          <p>
            Our company is a result of 35-year tradition of strong teamwork.<br/>
            We work internationally with people who are with us from the very beginning of our enterprise, which started as a small family business and it’s the main part of our great success.<br/>
            We have extensive experience in customer relations and in cleaning, washing, preparing, and shipping top quality feather goods in large amounts.<br/>
            Nowadays, the production of special down and feather for fillings in upholstered furniture is dominating the market.<br/>
            Our products are certified by FIRA, IDFL.<br/>
            We fulfill not only domestic orders but also arrange European import and export.<br/>
            Our current clients are bedding and decorative pillows manufacturers and products such as quilts, pillows, and sports jacket.<br/>
          </p>
        </span>

      </div>
    </div>
  </SectionView>

  <SectionView :header-image="require('../src/assets/section-headers/2-premium.jpeg')" id="premium" >
    <div class="row">
      <div class="col section-content mt-3">
        <span class="pl-text">
          <p>
            Pierze i puch od lat stanowią jedno z najlepszych, naturalnych wypełnień kołder i poduszek. Doskonały surowiec i doświadczenie w jego przetwarzaniu pozwala naszej firmie utrzymać znakomite relacje handlowe. Od wielu lat Puch z Gęsi Białej Kołudzkiej jest najlepszym i najbardziej wartościowym puchem na świecie.<br/>
            Wykorzystywany jest do produkcji kurtek wysokogórskich i pościeli. W Instytucie Zootechniki, aby uniknąć zafałszowań, na ukończeniu jest opracowanie metody identyfikacji puchu na podstawie DNA.
          </p>
          <img class="eco-friendly" src="./assets/eco-friendly.png">
          <p>
            Osiąga najwyższe parametry sprężystości.<br/>
            Produkt przyjazny ekologicznie oraz w 100% biodegradowalny.<br/>
            Pozyskiwany  tylko z ferm certyfikowanych , odpowiedzialnych za środowisko i dobrostan.<br/>
            Pochodzenia polskiego  w 100%.
          </p>

        </span>

        <span class="en-text">
          <p>
            For years, feather and down have been one of the best natural fillings of quilts and pillows. Posession of matchless raw material and experience in its processing allow our company to maintain excellent business relations. For an endless time, the White Koluda Goose Down has been the best and most valuable down in the world. It is used in the production of alpine jackets and bedding. To In order to maintain our supreme quality, the National Research Institute of Animal Production audits the finished material using a method of identifying down by DNA.<br/>
          </p>
          <img class="eco-friendly" src="./assets/eco-friendly.png">
          <p>
            Our product:<br/>
            Achieves the highest parameters of elasticity,<br/>
            Is ecologically friendly and 100% biodegradable,<br/>
            Is obtained only from certified farms responsible for the environment,<br/>
            Has 100% Polish origin.
          </p>
        </span>

      </div>
    </div>
  </SectionView>

  <SectionView :header-image="require('../src/assets/section-headers/3-galeria.jpeg')" id="galeria" >
    <div class="row">
      <div class="gallery-item col-md-4" v-for="(item,index) in galleryItems" :key="index">
        <a :href="item">
          <img :src="item" alt="gallery item" class="img-fluid p-4"/>
        </a>
      </div>
    </div>
  </SectionView>

  <SectionView :header-image="require('../src/assets/section-headers/4-kontakt.jpeg')" id="kontakt" >
    <div class="row">
      <div class="col-md-4 content-info offset-md-2">
        <span class="fw-bolder">PUCH TRADE Sp. z o.o.</span><br/>
        ul. Przemysłowa 54/78C<br/>
        62–510 Konin<br/>
        NIP 665-303-78-33<br/>
      </div>
      <div class="col-md-4 content-info pt-2" style="text-align: right">
        <a href="mailto:biuro@puchtrade.com">biuro@puchtrade.com</a><br/>
        <a href="mailto:export@puchtrade.com">export@puchtrade.com</a><br/>
        tel. +48 60 392 4796<br/>
      </div>
    </div>
  </SectionView>

  <div class="container-fluid bottom-bar">
  </div>


</template>

<script>

import MainMenu from "@/components/MainMenu";
import SliderView from "@/components/Slider";
import SectionView from "@/components/SectionView";

export default {
  name: 'App',
  components: {
    SectionView,
    SliderView,
    MainMenu
  },

  data() {
    return {
      menuItems: [
        {name: "Home", link: "#home"},
        {name: "Puch Premium", link: "#premium"},
        {name: "Garment", link: "#garment"},
        {name: "Galeria", link: "#galeria"},
        {name: "Kontakt", link: "#kontakt"}
      ],

      sliderItems: [
        {imgSrc:  require("../src/assets/slider/1.jpeg"), link: "#home"},
        {imgSrc:  require("../src/assets/slider/2.jpeg"), link: "#garmet"},
        {imgSrc:  require("../src/assets/slider/3.jpeg"), link: "#premium"},
      ],

      galleryItems: [
        require("../src/assets/gallery/p1.png"),
        require("../src/assets/gallery/p2.png"),
        require("../src/assets/gallery/p3.png"),
        require("../src/assets/gallery/p4.png"),
        require("../src/assets/gallery/p5.png"),
        require("../src/assets/gallery/p6.png"),
        require("../src/assets/gallery/p7.png"),
        require("../src/assets/gallery/p8.png"),
        require("../src/assets/gallery/p9.png"),
        require("../src/assets/gallery/p10.png"),
        require("../src/assets/gallery/p11.png"),
        require("../src/assets/gallery/p12.png"),
        require("../src/assets/gallery/k1.png"),
        require("../src/assets/gallery/k2.png"),
        require("../src/assets/gallery/k3.png"),
        require("../src/assets/gallery/k4.png"),
        require("../src/assets/gallery/k5.png"),
        require("../src/assets/gallery/k6.png"),
      ]

    }
  }


}
</script>



<style lang="scss">
$main-color: #0061B5;
@use "./css/hover.css";
@use "./assets/lightbox/css/lightbox.css";

#app {
  a { color: $main-color }

  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.eco-friendly {
  float: right;
  width: 90px;
}

.content-info {
  color: $main-color;
}

.bottom-bar {
  background-color: $main-color;
  width: 100%;
  height: 70px;
  margin-top: 100px;
}
@import'~bootstrap/dist/css/bootstrap.css';

</style>
