<template>
  <nav class="navbar navbar-expand-md fixed-top">

    <div class="container">

      <a class="navbar-brand" href="#">
        <img class="logo" alt="logo" src="../assets/logo.png">
      </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="float-end align-content-end nav justify-content-end">
          <li v-for="item in menuItems" :key="item.link" class="nav-item">
            <a class="nav-link hvr-underline-from-center " :href="item.link">{{item.name}}</a>
          </li>
        </ul>
      </div>

    </div>

  </nav>
</template>

<script>
export default {
  name: 'MainMenu',
  props: {
    items: Array
  },
  data() {
    return {
      menuItems: this.items,
    }
  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

nav {
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0px 0px 5px #aaa;

  img {
    height: 50px
  }



  .navbar-collapse {
    display: block !important;
  }
}

.ml-auto .dropdown-menu {
  left: auto !important;
  right: 0px !important;
}

</style>
